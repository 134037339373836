import React from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const Login = () => {
  return (
    <div className="handleImg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="handleHeading">
              <h3>Welcome Back!</h3>
            </div>
            <div className="handleInput">
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermIdentityIcon
                        style={{ backgroundColor: "#0383b0", color: "#fff" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <br />
              <TextField
                style={{ padding: "20px" }}
                className="input"
                variant="outlined"
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOpenIcon
                        style={{ backgroundColor: "#0383b0", color: "#fff" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              <br />
              <button className="handleBtn">Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
