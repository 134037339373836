import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import SearchIcon from "@mui/icons-material/Search";
import { Container, InputAdornment, TextField, colors } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Home() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}> */}
      {/* <Toolbar>
         
          <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography>
        </Toolbar> */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        //   onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
        style={{ backgroundColor: "black" }}
      >
        <MenuIcon />
      </IconButton>
      {/* </AppBar> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <Divider />
        <List className="list custom-font-family">
          {[
            "Dashboard",
            "Add Project",
            "Client",
            "Supplier",
            "Library",
            "User",
            "Report",
            "Help",
          ].map((text, index) => (
            <ListItem key={text} disablePadding className="list-item">
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} className="list-item-text" />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      <Main open={open}>
        <h5 className="handleHeader">Project</h5>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Container>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <KeyboardArrowRightIcon />
                      </InputAdornment>
                    ),
                  }}
                  id="search"
                  type="search"
                  sx={{ width: 350 }}
                />
              </Container>
            </div>
            <div className="col-md-9">
              <ul className="handleUl">
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList">
                  <label className="handleLabel">38</label>
                  <p>Total Projects</p>
                </li>
                <li className="hanleList"></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="table-container">
          <table className="custom-table">
            <thead className="handletableRow">
              <tr className="handletableRow">
                <th>S.No</th>
                <th>Action</th>
                <th>Project Code</th>
                <th>Project Name</th>
                <th>Client Name</th>
                <th>Country</th>
                <th>Manager</th>
                <th>ProScreen</th>
                <th>Complete</th>
                <th>Terminate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Action</td>
                <td>Project Code</td>
                <td>Project Name</td>
                <td>Client Name</td>
                <td>Country</td>
                <td>Manager</td>
                <td>ProScreen</td>
                <td>Complete</td>
                <td>Terminate</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Action</td>
                <td>Project Code</td>
                <td>Project Name</td>
                <td>Client Name</td>
                <td>Country</td>
                <td>Manager</td>
                <td>ProScreen</td>
                <td>Complete</td>
                <td>Terminate</td>
              </tr>
              {/* Add more rows here */}
            </tbody>
          </table>
        </div>
      </Main>
    </Box>
  );
}
